
<template>
  <fullscreenImage :url=clickedImageUrl />
  <div v-for="block in replaceHTMLHeadings(content)" :key="block" :class="{'bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-2 mb-5 font-visby' : block?.type !== 'heading' && displayType !== 'clean'}" >
    <div v-if="block.type === 'image'"  @click="showFullscreenImage" class="w-full cursor-pointer">
      <div v-html=block.content></div>
      <div v-if="block.caption"  v-html=block.caption class="text-xs mt-5 text-center italic break-words hyphen"></div>
    </div>
    <div v-if="block.type === 'heading'" v-html=block.content class="break-words hyphen"></div>
    <div v-if="block.type === 'youtubeVideo'">
      <iframe class="w-full h-auto" :src="block.content" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div v-for="subBlock in block" :key="subBlock" class="inline" :class="{'leading-loose' : extraLineHeight}">
      <span v-if="subBlock.type !== 'placeholder'" v-html=subBlock?.content class="break-words hyphen" :class="color === 'white' ? 'text-white' : 'text-new-black'"></span>
      <span v-else class="border border-new-black rounded px-2 py-1 mx-1 text-white"
          :class="{'bg-new-black' : selectedAnswers[subBlock.placeholderIndex] && selectedAnswers[subBlock.placeholderIndex] !== 'OPTION', 'ring-2 ring-new-orange' : selectedPlaceholder === subBlock.placeholderIndex}"
          @click="setSelectedPlaceholder(subBlock.placeholderIndex)">{{ selectedAnswers[subBlock.placeholderIndex] || subBlock.content }}</span>
    </div>
  </div>
</template>

<script>
// Components
import fullscreenImage from './fullscreenImage.vue';


export default {
  name: 'tipTap',
  props: ['content', 'displayType', 'color', 'selectedAnswers', 'extraLineHeight', 'type'],
  components: { fullscreenImage },


  data() {
    return {
      clickedImageUrl: null,
    };
  },

  computed: {
    selectedPlaceholder() {
      return this.$store.state.learning?.selectedPlaceholder;
    },
  },

  methods: {

    validateEmptyReadableContent(blocks) {
      const cleanedBlocks = blocks;
      blocks.forEach((block, blockIndex) => {
        if (typeof block === 'object' && block !== null && block.type === 'content') {
          const repairedBlock = this.repairHTML(block.content);
          if (repairedBlock !== null) {
            cleanedBlocks[blockIndex].content = repairedBlock;
          } else {
            cleanedBlocks.splice(blockIndex, 1);
          }
        } else if (Array.isArray(block)) {
          block.forEach((subBlock, subBlockIndex) => {
            if (subBlock.type === 'content') {
              const repairedBlock = this.repairHTML(subBlock.content);
              if (repairedBlock !== null) {
                cleanedBlocks[blockIndex][subBlockIndex].content = repairedBlock;
              } else {
                cleanedBlocks[blockIndex].splice(subBlockIndex, 1);
              }
            }
          });
        }
      });
      return cleanedBlocks;
    },

    repairHTML(content) {
      if (content) {
        const dom = new DOMParser().parseFromString(content, 'text/html');
        const body = dom.querySelector('body');
        const hasContent = dom.querySelector('body').textContent;
        body.querySelectorAll('span').forEach((node) => {
          node.removeAttribute('style');
        });
        const output = body.innerHTML;
        return hasContent !== '' ? output : null;
      }
    },

    removedEmptyArrays(blocks) {
      const cleanedBlocks = blocks;
      blocks.forEach((block, blockIndex) => {
        if ((Array.isArray(block) && block.length === 0) || (Array.isArray(block) && block.length === 1 && block[0].type === 'content' && !block[0].content)) {
          cleanedBlocks.splice(blockIndex, 1);
        }
      });
      return cleanedBlocks;
    },

    setSelectedPlaceholder(index) {
      this.$store.commit('setSelectedPlaceholder', index);
    },

    cleanBeginnings(blocks) {
      const cleanedBlocks = blocks;
      blocks.forEach((block, blockIndex) => {
        if (Array.isArray(block)) {
          block.forEach((subBlock, subBlockIndex) => {
            if (subBlock.type === 'content' && subBlock.content) {
              if (subBlock?.content.startsWith('<p style="text-align: start"></p>')) {
                const subString = subBlock.content.replace('<p style="text-align: start"></p>', '');
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.startsWith('<p><br>')) {
                const subString = subBlock.content.replace('<br>', '');
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.startsWith('<br><p></p>')) {
                const subString = subBlock.content.replace('<br><p></p>', '');
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.startsWith('<p style="text-align: start"><br>')) {
                const subString = subBlock.content.replace('<br>', '');
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.startsWith('<br><br>')) {
                const subString = subBlock.content.replace('<br><br>', '');
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              }

              if (subBlock?.content.endsWith('<p></p><p></p>')) {
                const subString = subBlock.content.substring(0, subBlock.content.length - 14);
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.endsWith('<p></p>')) {
                const subString = subBlock.content.substring(0, subBlock.content.length - 7);
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              } else if (subBlock?.content.endsWith('<p style="text-align: start"></p>')) {
                const subString = subBlock.content.substring(0, subBlock.content.length - 33);
                cleanedBlocks[blockIndex][subBlockIndex].content = subString;
              }
            }
          });
        }
      });
      return cleanedBlocks;
    },

    replaceHTMLHeadings(content) {
      if (content) {
        let cleanedHeadings = content;
        cleanedHeadings = cleanedHeadings?.replaceAll('<h2', '<h1');
        cleanedHeadings = cleanedHeadings?.replaceAll('</h2>', '</h1>');
        cleanedHeadings = cleanedHeadings?.replaceAll('<h3', '<h1');
        cleanedHeadings = cleanedHeadings?.replaceAll('</h3>', '</h1>');
        cleanedHeadings = cleanedHeadings?.replaceAll('<h4', '<h6');
        cleanedHeadings = cleanedHeadings?.replaceAll('</h6>', '</h6>');
        cleanedHeadings = cleanedHeadings?.replaceAll('<h5', '<h6');
        cleanedHeadings = cleanedHeadings?.replaceAll('</h5>', '</h6>');
        cleanedHeadings = cleanedHeadings?.replaceAll('<hr>', '<h1></h1>');
        const cleanedNBSP = cleanedHeadings?.replaceAll('&nbsp;', ' ');
        const formattedContent = this.splitIntoBlocks(cleanedNBSP);
        const withSplittedCaptions = this.splitCaption(formattedContent);
        const finallyCleaned = this.validateEmptyReadableContent(withSplittedCaptions);
        const removedEmptyArrays = this.removedEmptyArrays(finallyCleaned);
        const cleanedEmptyStart = this.cleanBeginnings(removedEmptyArrays);
        return cleanedEmptyStart;
      }
    },

    splitIntoBlocks(content) {
      const blockLength = content.split(/<h1|<\/h1>/g).filter((element) => element !== '').length;
      const blocks = [];
      let lastFound = 0;
      for (let i = 0; i < blockLength; i += 1) {
        const first = content.indexOf('<h1', lastFound);
        const last = content.indexOf('</h1>', lastFound);
        if (first === -1 && last === -1) {
          const sub = content.substring(lastFound, content.length);
          this.splitImages(sub).forEach((splitted) => {
            blocks.push(splitted);
          });
        } else
        if (first > lastFound) {
          const sub = content.substring(lastFound, first);
          this.splitImages(sub).forEach((splitted) => {
            blocks.push(splitted);
          });
          lastFound = first;
        } else {
          const sub = content.substring(first, last + 5);
          this.findPlaceholderSpots(sub);
          blocks.push({ type: 'heading', content: sub });
          lastFound = last + 5;
        }
      }
      return this.setPlaceholderIndex(blocks);
    },

    splitImages(subContent) {
      let subContentLength = subContent.length;
      const blocks = [];
      let lastFound = 0;
      while (subContentLength > 0) {
        const imgStart = subContent.indexOf('<img src', lastFound);
        const imgEnd = subContent.indexOf('>', imgStart);
        if (imgStart === -1) {
          const sub = subContent.substring(lastFound, subContent.length);
          this.splitVideos(sub).forEach((splitted) => {
            blocks.push(splitted);
          });
          subContentLength = 0;
        } else if (imgStart > lastFound) {
          const sub = subContent.substring(lastFound, imgStart);
          const rest = subContent.substring(imgStart, subContent.length);
          this.splitVideos(sub).forEach((splitted) => {
            blocks.push(splitted);
          });
          lastFound = imgStart;
          subContentLength = rest.length;
        } else {
          const sub = subContent.substring(imgStart, imgEnd + 1);
          const rest = subContent.substring(imgEnd + 1, subContent.length);
          blocks.push({ type: 'image', content: sub });
          lastFound = imgEnd + 1;
          subContentLength = rest.length;
        }
      }
      return blocks;
    },

    splitVideos(subContent) {
      let subContentLength = subContent.length;
      const blocks = [];
      let lastFound = 0;
      let lastRealVideo = 0;
      while (subContentLength > 0) {
        const vidStart = subContent.indexOf('<a', lastFound);
        const vidEnd = subContent.indexOf('</a>', vidStart);
        if (vidStart === -1) {
          const sub = subContent.substring(lastRealVideo !== 0 ? lastFound : lastRealVideo, subContent.length);
          blocks.push(this.findPlaceholderSpots(sub));
          subContentLength = 0;
        } else if (vidStart > lastFound) {
          const rest = subContent.substring(vidStart, subContent.length);
          const nextLinkEnd = rest.indexOf('</a>', 0);
          const nextLinkIsYoutube = rest.substring(0, nextLinkEnd + 4).includes('YOUTUBE');
          if (nextLinkIsYoutube) {
            blocks.push(this.findPlaceholderSpots(subContent.substring(lastRealVideo, vidStart)));
          }
          lastFound = vidStart;
          subContentLength = rest.length;
        } else {
          const sub = subContent.substring(vidStart, vidEnd + 4);
          const rest = subContent.substring(vidEnd + 4, subContent.length);
          const hrefStart = sub.indexOf('href="');
          const hrefEnd = sub.indexOf('">', hrefStart);
          const videoLink = sub.substring(hrefStart + 6, hrefEnd);
          if (sub.includes('YOUTUBE')) {
            blocks.push({ type: 'youtubeVideo', content: this.formatVideoLink(videoLink) });
            lastRealVideo = vidEnd + 4;
          }
          lastFound = vidEnd + 4;
          subContentLength = rest.length;
        }
      }
      return blocks;
    },

    formatVideoLink(link) {
      if (!link.includes('embed')) {
        if (link.includes('.be')) {
          const query = link.split('/').slice(-1).pop();
          if (query) return `https://www.youtube-nocookie.com/embed/${query}`;
        } else if (link.includes('watch?v=')) {
          const query = link.split('v=').slice(-1).pop();
          if (query) return `https://www.youtube-nocookie.com/embed/${query}`;
        }
      }
      return link;
    },

    splitCaption(formattedContent) {
      const updatedContent = formattedContent;
      formattedContent.forEach((block, blockIndex) => {
        if (Array.isArray(block)) {
          block.forEach((subBlock, subBlockIndex) => {
            if (subBlock.type === 'content') {
              const captionStart = subBlock.content.indexOf('&lt;caption&gt;', 0);
              const captionEnd = subBlock.content.indexOf('&lt;/caption&gt;', 0);
              if (captionEnd > captionStart) {
                let sub = subBlock.content.substring(captionStart, captionEnd + 16);
                updatedContent[blockIndex][subBlockIndex].content = subBlock.content.replace(sub, '');
                if (formattedContent[blockIndex - 1]?.type === 'image') {
                  sub = sub.replace('&lt;caption&gt;', '').replace('&lt;/caption&gt;', '');
                  updatedContent[blockIndex - 1].caption = sub;
                }
              }
            }
          });
        }
      });
      return formattedContent;
    },

    findPlaceholderSpots(subContent) {
      const blockLength = subContent.split(/<s>|<\/s>/g).filter((element) => element !== '').length;
      const blocks = [];
      let lastFound = 0;
      for (let i = 0; i < blockLength; i += 1) {
        const first = subContent.indexOf('<s>', lastFound);
        const last = subContent.indexOf('</s>', lastFound);
        if (first === -1 && last === -1) {
          let sub = subContent.substring(lastFound, subContent.length);
          sub = this.replaceHTMLTags(sub);
          blocks.push({ type: 'content', content: sub });
        } else
        if (first > lastFound) {
          let sub = subContent.substring(lastFound, first);
          sub = this.replaceHTMLTags(sub);
          blocks.push({ type: 'content', content: sub });
          lastFound = first;
        } else {
          let sub = subContent.substring(first, last + 4);
          sub = 'OPTION';
          blocks.push({ type: 'placeholder', content: sub });
          lastFound = last + 4;
        }
      }
      return blocks;
    },

    setPlaceholderIndex(blocks) {
      const newBlocks = blocks;
      let placeholderIndex = 0;
      newBlocks.forEach((subBlock, subIndex) => {
        if (subBlock?.length > 0) {
          subBlock.forEach((block, blockIndex) => {
            if (block.type === 'placeholder') {
              newBlocks[subIndex][blockIndex].placeholderIndex = placeholderIndex;
              placeholderIndex += 1;
            }
          });
        }
      });
      return newBlocks;
    },

    /* cleanUpEmptyBlocks(blocks) {
      const cleanedBlocks = blocks;
      const lastBlock = cleanedBlocks[cleanedBlocks?.length - 1];
      const firstBlock = cleanedBlocks[0];
      if (lastBlock.length === 1 && (lastBlock[0].content === '<br>' || lastBlock[0].content === '<br><br>' || lastBlock[0].content === '')) {
        cleanedBlocks.pop();
      }
      if (firstBlock.length === 1 && firstBlock[0].content === '') {
        cleanedBlocks.shift();
      }
      cleanedBlocks.forEach((block, index) => {
        if (block.length === 1 && block[0]?.type === 'content' && block[0]?.content === '') cleanedBlocks.splice(index, 1);
        if (block[0]?.type === 'content' && block[0]?.content.startsWith('<br>')) {
          while (cleanedBlocks[index][0].content.startsWith('<br>')) {
            const replaced = block[0].content.replace('<br>', '');
            cleanedBlocks[index][0].content = replaced;
          }
        }
        if (block[0]?.type === 'content' && block[0]?.content.endsWith('<br>')) {
          while (cleanedBlocks[index][0].content.endsWith('<br>')) {
            const replaced = block[0].content.replace('<br>', '');
            cleanedBlocks[index][0].content = replaced;
          }
        }
      });
      return cleanedBlocks;
    }, */

    replaceHTMLTags(subString) {
      if (this.type === 'lückentext') {
        let sub = subString.replaceAll('<p>', '');
        sub = sub.replaceAll('</p>', '<br>');
        return sub;
      }
      return subString;
    },

    showFullscreenImage(event) {
      if (event?.target?.nodeName === 'IMG' && event?.target?.currentSrc) {
        this.clickedImageUrl = event.target.currentSrc;
        this.$store.commit('showOverlay', 'fullscreenImage');
      }
    },
  },
};
</script>

<style>
a {
  @apply text-blue-500 underline
}
h1 {
  @apply text-3xl font-visby font-bold mt-2
}
ol {
  @apply list-decimal pl-5
}
ul {
  @apply list-disc pl-5
}
ul ul {
   list-style-type: circle;
   }
p {
  @apply min-h-2
}
.hyphen {
    -webkit-hyphens: auto; /* for Safari */
    hyphens: auto;
}
</style>
